import { Component } from '@angular/core';

@Component({
  selector: 'sk-graph-information',
  templateUrl: './graph-information.component.html',
  styleUrls: ['./graph-information.component.scss']
})
export class GraphInformationComponent {

  window: Window = window;
}
