<form [formGroup]="graphForm" (ngSubmit)="onSubmit(defaultQueryView)" class="filter-form-content">
  <mat-grid-list [cols]="9" rowHeight="75px" class="filter-selectors">

    <div *ngIf="defaultQueryView">
      <mat-grid-tile [rowspan]="1" colspan="8">
        <mat-label>Select Query:</mat-label>
        <mat-form-field class="query-input">
          <mat-select [(value)]="selectedEndpoint">
            <mat-option *ngFor="let endpoint of endpoints" value={{endpoint[1]}}>{{endpoint[0]}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </div>

    <div *ngIf="!defaultQueryView">
      <mat-grid-tile [rowspan]="1" colspan="8">
        <mat-select [(value)]="betaEndpoint" class="custom-query-version" placeholder="https://graph.microsoft.com/v1.0/">
          <mat-option [value]="false">v1.0</mat-option>
          <mat-option [value]="true">beta</mat-option>
        </mat-select>
        <mat-form-field class="custom-query-input">
          <input class="custom-query-uri" matInput autocomplete="off" placeholder="Enter Uri" formControlName="uri" name="uri">
        </mat-form-field>
      </mat-grid-tile>
    </div>

    <mat-grid-tile [rowspan]="1" colspan="1">
      <button mat-raised-button type="submit" [disabled]="isLoading" [ngClass]="{ enabled: !isLoading }">
        <span *ngIf="!isLoading">Send</span>
        <mat-spinner *ngIf="isLoading" class="loading-spinner" [diameter]="36"></mat-spinner>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</form>

<span class="clickable query-toggle" (click)="toggleQueryView()">
  {{defaultQueryView ? 'Enable advanced queries' : 'Load default queries'}}
</span>

<div class="graph-result">
  <div class="json-viewer-container">
    <ngx-json-viewer id="json-viewer"
      *ngIf="queryResultsCurrentPage"
      [json]="queryResultsCurrentPage.content">
    </ngx-json-viewer>
  </div>

  <div *ngIf="queryResultsCurrentPage">
    <mat-grid-list [cols]="8" rowHeight="45px" class="pagination">
        <mat-grid-tile [rowspan]="1" [colspan]="8">
          <button mat-raised-button
            *ngFor="let item of [].constructor(queryResultPages.length); let i = index;"
            [disabled]="isLoading || (i + 1 == queryResultsCurrentPage.pageNumber)"
            (click)="jumpToPage(i + 1)">
            {{i + 1}}
          </button>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list [cols]="8" rowHeight="45px" class="pagination">
      <mat-grid-tile [rowspan]="1" [colspan]="1">

        <button  mat-raised-button
          (click)="copyJson()">
          <span>Copy</span>
        </button>

      </mat-grid-tile>

      <mat-grid-tile [rowspan]="1" [colspan]="6">
        <button mat-raised-button
          [disabled]="!canPageBackwards()"
          (click)="pageBackwards()">
          <span><<</span>
        </button>

        <button  mat-raised-button
          [disabled]="!canPageForwards()"
          (click)="pageForwards()">
          <span>>></span>
        </button>

      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
