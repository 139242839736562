import { SkippedRestoreItemsReportComponent } from './shared/skipped-restore-items-report/skipped-restore-items-report.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DeveloperComponent } from './developer/developer.component';

import { RestoreJobHistoryComponent } from './restores/restore-job-history.component';

import { OrderComponent } from './order/order.component';
import { AccountingComponent } from './accounting/accounting.component';
import { JobDetailsComponent } from './shared/job-details/job-details.component';
import { SubscriptionDashboardComponent } from './subscriptions/subscription-dashboard/subscription-dashboard.component';
import { SubscriptionComponent } from './subscriptions/subscription/subscription.component';
import { GraphExplorerComponent } from './graph-explorer/graph-explorer.component';
import { RestoreDetailsComponent } from './shared/restore-details/restore-details.component';
import { IpCheckerComponent } from './ip-checker/ip-checker.component';
import { MsalGuard } from '@azure/msal-angular';
import { SnapshotDetailsComponent } from './shared/snapshot-details/snapshot-details.component';
import { SearchOverviewComponent } from './search-overview/search-overview.component';
import { PartnerSearchComponent } from './partner-search/partner-search.component';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import { TenantUsersComponent } from './order/tenant-users/tenant-users.component';
import { AccessAppAuthComponent } from './access/access-app-auth/access-app-auth.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'search', component: SearchOverviewComponent },
      { path: 'developer', component: DeveloperComponent },
      { path: 'restores', component: RestoreJobHistoryComponent },

      { path: 'partners', component: PartnerSearchComponent },
      { path: 'partners/:partnerId', component: PartnerDetailsComponent },

      { path: 'ip-checker', component: IpCheckerComponent },

      { path: 'orders/:id', redirectTo: 'orders/:id/order/order-details' },
      { path: 'orders/:id/order', redirectTo: 'orders/:id/order/order-details' },
      { path: 'orders/:id/accounting', redirectTo: 'orders/:id/accounting/promo-details' },
      { path: 'orders/:id/graph-explorer', redirectTo: 'orders/:id/graph-explorer/explorer' },
      { path: 'orders/:id/order/order-details', component: OrderComponent },
      { path: 'orders/:id/order/jobs', component: OrderComponent },
      { path: 'orders/:id/order/jobs/:jobId', component: JobDetailsComponent },
      { path: 'orders/:id/order/restores', component: OrderComponent },
      { path: 'orders/:id/order/reindex-history', component: OrderComponent },
      { path: 'orders/:id/order/snapshot-health', component: OrderComponent },
      { path: 'orders/:id/order/order-actions', component: OrderComponent },
      { path: 'orders/:id/order/audit', component: OrderComponent },
      { path: 'orders/:id/order/licensing', component: OrderComponent },
      { path: 'orders/:id/accounting/promo-details', component: AccountingComponent },
      { path: 'orders/:id/accounting/billing', component: AccountingComponent },
      { path: 'orders/:id/app-auth', component: AccessAppAuthComponent },
      { path: 'orders/:id/subscriptions', component: SubscriptionDashboardComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/subscription-details', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/subscription-details', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/subscription-health', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/subscription-browser', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/subscription-reindex-history', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/subscription-search', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/jobs', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/audit', component: SubscriptionComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/jobs/:jobId', component: JobDetailsComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/jobs/:jobId', component: JobDetailsComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/jobs/:jobId/restore-details', component: RestoreDetailsComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/jobs/:jobId/snapshot-details', component: SnapshotDetailsComponent },
      { path: 'orders/:id/graph-explorer', component: GraphExplorerComponent },
      { path: 'orders/:id/graph-explorer/explorer', component: GraphExplorerComponent },
      { path: 'orders/:id/graph-explorer/information', component: GraphExplorerComponent },
      { path: 'orders/:id/graph-explorer/ms-graph-explorer', component: GraphExplorerComponent },
      { path: 'restores/skipped-restore-items-report', component: SkippedRestoreItemsReportComponent },
      { path: 'orders/:id/subscriptions/:subscriptionId/:resourceType/graph-journey', component: SubscriptionComponent },
      { path: 'orders/:id/usersgroups', loadChildren: () => import('./users-groups/users-groups.module').then(m => m.UsersGroupsModule) },
      { path: 'orders/:id/snapshot-frequencies', loadChildren: () => import('./snapshot-frequencies/snapshot-frequencies.module').then(m => m.SnapshotFrequenciesModule) },
      { path: 'orders/:id/onedrive-discovery', component: TenantUsersComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
