<div class="card-links">
  <mat-card class="information-card" (click)="window.open('https://docs.microsoft.com/en-us/graph', '_blank')">
    <h5>Graph API Overview</h5>
    <p>A good jumping off point for finding information about the Graph API</p>
  </mat-card>

  <mat-card class="information-card"
    (click)="window.open('https://docs.microsoft.com/en-us/graph/api/overview?view=graph-rest-1.0', '_blank')">
    <h5>API Endpoints</h5>
    <p>A comprehensive list of endpoints and resources in the Graph API</p>
  </mat-card>

  <mat-card class="information-card" (click)="window.open('https://docs.microsoft.com/en-us/graph/query-parameters', '_blank')">
    <h5>Query Parameters</h5>
    <p>A guide on the possible query parameters you can include in the Graph Api query</p>
  </mat-card>
</div>
