import { Moment } from 'moment';

export class Promo {
    /* fields */
    id: string;
    name: string;
    active: string;
    promoCode: string;
    startDate: Moment;
    endDate: Moment;
    amount: number;
    cost: number;
    invoiceSku: string;
    invoiceDescription: string;
    secondaryAmount: number;
    secondaryCost: number;
    secondaryInvoiceSku: string;
    secondaryInvoiceDescription: string;
    orderType: string;
    specialPromoId: string;
    pricingMethod: string;

    /* flags */
    allowExchangeBackup: boolean;
    allowExchangeSharedBackup: boolean;
    manageExchangeIndividual: boolean;
    manageExchangeShared: boolean;
    excludeExchangeSharedResourcesCharges: boolean;

    allowGroupsBackup: boolean;
    manageGroups: boolean;

    allowOneDriveBackup: boolean;
    allowSharePointBackup: boolean;
    manageOneDrive: boolean;
    manageSharePoint: boolean;

    allowGroupsManagement: boolean;
    allowUsersManagement: boolean;

    /* computed */
    source: PromoSource;
    sourceTitle: string;
    sourceAbbreviation: string;
}

export enum PromoSource {
    Traditional = 'Traditional',
    Marketplace = 'Marketplace',
    APIDirect = 'APIDirect'
}
