import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ODataResponse } from '@sk-models';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { AzureGroupType, Group } from 'app/users-groups/models/group.model';
import { UsersGroupsSearchFilter, GroupsSearchResult, SearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { UsersGroupsService } from 'app/users-groups/services/users-groups.service';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'sk-groups-search-grid',
  templateUrl: './groups-search-grid.component.html',
  styleUrls: ['./groups-search-grid.component.scss']
})
export class GroupsSearchGridComponent extends SkyKickComponent implements OnInit {
  @Input() backupServiceId: string;
  @Input() userId: string;
  @Input() groupId: string;
  @Input() filterSearchTerm: string;

  public isLoading = true;
  public hasData = false;

  // Pagination
  pageSizeOptions = [12, 50, 100];
  totalResults: number;

  subject = new Subject();
  filter: UsersGroupsSearchFilter;

  dataSource: MatTableDataSource<GroupsSearchResult>;
  displayedColumns = ['id', 'name', 'type', 'protected', 'created'];
  azureGroupTypeEnum: AzureGroupType;

  constructor(private usersGroupsService: UsersGroupsService ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.subs.sink = this.subject.pipe(
      switchMap((data: UsersGroupsSearchFilter) => {
        this.isLoading = true;
        return this.executeSearch(data);
      })
    ).subscribe((uag: ODataResponse<Group>) => {
      this.hasData = true
      this.totalResults = uag['@odata.count'];
      this.dataSource = new MatTableDataSource(uag.value);
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    });
    this.filter = {
      page: 0,
      size: this.pageSizeOptions[0],
      sort: 'name',
      sortDirection: 'asc',
      term: this.filterSearchTerm };
  }

  public refresh(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.subject.next(this.filter);
  }

  executeSearch(filter: UsersGroupsSearchFilter): Observable<ODataResponse<GroupsSearchResult>> {
    if (this.userId) {
      return this.usersGroupsService
        .searchUserGroupMemberships(this.backupServiceId, this.userId, filter);
    } else if (this.groupId) {
      return this.usersGroupsService
        .searchGroupGroupMemberships(this.backupServiceId, this.groupId, filter);
    } else {
      return this.usersGroupsService
        .searchGroups(this.backupServiceId, filter);
    }
  }

  onPageChanged(event: PageEvent): void {
    this.filter.size = event.pageSize;
    this.filter.page = event.pageIndex;
    this.subject.next(this.filter);
  }

  onFilterChanged(term: string): void {
    this.filter = {
      ...this.filter,
      term: term
    };
    this.subject.next(this.filter);
  }

  onSortChanged(sort: Sort): void {
    this.filter.sort = sort.active;
    this.filter.sortDirection = (sort.direction);
    this.subject.next(this.filter);
  }

  getGroupTypeName(groupType: number): string {
    return AzureGroupType[groupType] || 'Unknown';
  }
}
