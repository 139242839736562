<div class="component-container">
  <sk-subtab-nav [tabs]="[
    {route: 'explorer', name: 'Graph Explorer'},
    {route: 'information', name: 'Information'},
    {route: 'ms-graph-explorer', name: 'MS Graph Explorer (preview)'}
    ]">
  </sk-subtab-nav>

  <sk-explorer *ngIf="activeTab == 'explorer'" [orderId]="orderId"></sk-explorer>

  <sk-graph-information *ngIf="activeTab == 'information'"></sk-graph-information>

  <div *ngIf="activeTab == 'ms-graph-explorer'">Redirecting...</div>
</div>
