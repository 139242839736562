<div class="bsp-content-container">
  <div class="d-flex flex-grow-1 title-container">
    <span class="title">Restore Job History</span>
  </div>

	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					Filters
				</mat-panel-title>
			</mat-expansion-panel-header>
			<form *ngIf="!isRefreshing" [formGroup]="filterForm" class="filter-form-content">
				<mat-grid-list [cols]="5" rowHeight="75px" class="filter-selectors">
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field class="search-form-field">
							<input matInput placeholder="Search..." formControlName="searchInput">
						</mat-form-field>
                                        </mat-grid-tile>
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
							<mat-select placeholder="Resource Type" formControlName="resourceType">
								<mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let resourceType of resourceTypes" [value]="resourceType">
									{{resourceType ? resourceType : '--'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-grid-tile>
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field>
							<mat-select placeholder="Status" formControlName="jobStatus">
								<mat-option [value]="'All'">All</mat-option>
								<mat-option *ngFor="let jobStatus of jobStatuses" [value]="jobStatus">
									{{jobStatus ? jobStatus : '--'}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</mat-grid-tile>
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<mat-form-field appearance="fill" (click)="picker.open()">
							<mat-label>Enter a date range</mat-label>
							<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
							<mat-date-range-input placeholder="Date Range" [rangePicker]="picker">
							  <input matStartDate placeholder="Start date" #dateRangeStart>
							  <input matEndDate placeholder="End date" #dateRangeEnd (dateChange)="refreshDatesJobs(dateRangeStart, dateRangeEnd)">
							</mat-date-range-input>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</mat-grid-tile>
					<mat-grid-tile [rowspan]="1" [colspan]="1">
						<button mat-raised-button (click)="resetCurrentFilter()">
							<mat-icon>clear</mat-icon>
							<span>Reset Filters</span>
						</button>
					</mat-grid-tile>
				</mat-grid-list>
			</form>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="mat-elevation-z8 grid-container">
    <sk-click-to-refresh
      class="refresh"
      [shouldDisplay]="jobs && jobs.length > 0"
      [spin]="isRefreshing"
      (clickEvent)="refreshJobs()">
    </sk-click-to-refresh>
		<div class="table-container">

      <mat-table [dataSource]="dataSource"
        matSort
        multiTemplateDataRows>

				<ng-container matColumnDef="started">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Started</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="icon">
							<mat-icon
								class="clickable expand-icon"
								title="Click to see more details."
								(click)="clickedChevron(row)"
								*ngIf="row != expandedRow">
								keyboard_arrow_down
							</mat-icon>
							<mat-icon
								class="clickable expand-icon"
								title="Click to close."
								(click)="clickedChevron(row)"
								*ngIf="row == expandedRow">
								keyboard_arrow_up
							</mat-icon>
						</div>
						<span>{{(row.started | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="lastUpdated">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Last Updated</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{(row.lastUpdated | friendlyDate:"MM/DD/YY hh:mm A") || '--'}}</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="sourceName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Source</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
            <sk-click-to-copy
							[displayText]="row.restoreParameters?.backupSubscriptionData?.sourceName"
							hoverText="(Click to copy!) {{row.restoreParameters?.backupSubscriptionData?.sourceName}}"
							[copyText]="row.restoreParameters?.backupSubscriptionData?.sourceName">
						</sk-click-to-copy>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="destinationName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Destination</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<sk-click-to-copy
							[displayText]="row.restoreParameters?.backupSubscriptionData?.destinationName"
							hoverText="(Click to copy!) {{row.restoreParameters?.backupSubscriptionData?.destinationName}}"
							[copyText]="row.restoreParameters?.backupSubscriptionData?.destinationName">
						</sk-click-to-copy>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="isRoot">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Full</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{row.restoreParameters?.isRoot ? 'True' : 'False'}}</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="resourceType">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Resource Type</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{row.resourceType || '--'}}</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="statusIconTitle">
					<mat-header-cell *matHeaderCellDef mat-sort-header>
						<span>Status</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>
							<mat-icon
								[class]="row.statusIconClass"
								[title]="row.statusIconTitle">
								{{row.statusIcon}}
							</mat-icon>
						</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="durationTotal">
					<mat-header-cell *matHeaderCellDef>
						<span>Duration</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<span>{{row.durationTotal || '--'}}</span>
					</mat-cell>
        </ng-container>

				<ng-container matColumnDef="errorMessage">
					<mat-header-cell *matHeaderCellDef>
						<span>Error</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
            <a *ngIf="row.failed && row.errorStackTrace"
              href=""
              [title]="row.errorStackTrace.length > 400
                ? row.errorStackTrace.substring(0,400) + '...'
                : row.errorStackTrace"
              target="_blank"
              (click)="showModal(row.errorStackTrace, 'Stack Trace'); $event.preventDefault();">
              {{ row.errorType }}
            </a>
						<span *ngIf="!row.failed || !row.errorStackTrace">--</span>
					</mat-cell>
        </ng-container>

        <ng-container matColumnDef="viewRestore">
					<mat-header-cell *matHeaderCellDef>
						<span>View Restore</span>
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<a href="{{selectRestore(row)}}">View Restore</a>
					</mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" class="expand-element-detail" [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
            <sk-restore-history-details
              *ngIf="row == expandedRow"
              [job]="row"
              (expandEvent)="expandEventHandler($event)"></sk-restore-history-details>
          </td>
        </ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns">
        </mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;"
          class="expand-element-row"
          [class.expand-expanded-row]="expandedRow === row">
        </mat-row>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
          class="expand-detail-row">
        </tr>

			</mat-table>

			<div *ngIf="!dataSource || isRefreshing" class="loading-spinner">
				<mat-spinner></mat-spinner>
			</div>
		</div>
		<mat-paginator [pageSizeOptions]="[12, 50, 100]" showFirstLastButtons></mat-paginator>
	</div>
</div>
