import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ApiService } from 'app/api-service/api.service';
import {
  SubscriptionGraphJourneyService,
} from 'app/graph-journey/services/subscription-graph-journey.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import {
  filter,
  take,
} from 'rxjs/operators';

import {
  AuthRoles,
  authUserInRole,
  BackupSubscription,
  BackupSubscriptionTypes,
  PreparationState,
} from '@sk-models';
import { AuthService, MessageService } from '@sk-services';

import {
  ElasticDetailsModalComponent,
} from './elastic-details-modal/elastic-details-modal.component';
import {
  BlobSizeModalComponent,
} from './blob-size-modal/blob-size-modal.component';
import {
  LicenseDetailsModalComponent,
} from './license-details/license-details-modal.component/license-details-modal.component';
import { PurgeModalComponent } from './purge-results/purge-modal.component';

@Component({
  selector: 'sk-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent implements OnInit {
  @Input() subscription: BackupSubscription;

  events: string;
  backupSizeLoading: boolean;
  isInProdOrLocal: boolean;
  relatedResourceMaps = [];
  relatedResources: string[];
  graphJourneyAvailable = false;
  userIsEngineer = false;
  preparationState: string;
  prepStateEnum: number;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private subscriptionGraphJourneyService: SubscriptionGraphJourneyService,
    private dialog: MatDialog,
    private router: Router,
    private messageService: MessageService
  ) {
    this.isInProdOrLocal = environment.environment == 'prod' || environment.environment == 'localhost';
  }

  ngOnInit(): void {
    if(this.subscription.subscriptionType == 'Teams'){
      this.relatedResourceMaps = ["Team", "TeamChannelFiles", "TeamChannelPosts", "ConversationMailbox", "Planner"];
    } else if(this.subscription.subscriptionType == 'Groups'){
      this.relatedResourceMaps = ["ConversationMailbox", "TeamSite", "Planner"];
    }

    this.authService.authData$.pipe(filter(x => x != null), take(1)).subscribe((data) => {
      this.graphJourneyAvailable = authUserInRole(data, AuthRoles.Engineer)
        && this.subscriptionGraphJourneyService.getInitialJourney(this.subscription)?.journey != null;

      this.userIsEngineer = authUserInRole(data, AuthRoles.Engineer);
    });

    this.filterResourceType();

    this.determinePreparationStateEnumValue();
  }

  filterResourceType(): void {
    const currentResourceType = this.subscription.resourceKey.resourceType;

    this.relatedResources = this.relatedResourceMaps
      .filter(x => x != currentResourceType);
  }

  openDataDogLogs(): void {
    const toTimestamp =  moment().valueOf();
    const fromTimestamp = moment().add(-7, 'days').valueOf();
    const url = `https://app.datadoghq.com/logs?query=%40Properties.SubscriptionId%3A${this.subscription.id}&from_ts=${fromTimestamp}&to_ts=${toTimestamp}&live=true`;
    window.open(url);
  }

  openAlerts(): void {
    let subType: string;
    switch (this.subscription.subscriptionType) {
    case BackupSubscriptionTypes.Exchange:
      subType = 'exchange';
      break;
    case BackupSubscriptionTypes.SharePoint:
      subType = 'site';
      break;
    case BackupSubscriptionTypes.Groups:
      subType = 'group';
      break;
    }

    const alertsWebPortal = `https://alertswebportal.skykick.com/alerts/search/${this.subscription.id}/${subType}`;
    window.open(alertsWebPortal);
  }

  openInPartnerPortal(): void {
    let baseUrl: string;

    switch (environment.environment) {
    case 'localhost':
    case 'prod':
      baseUrl = 'https://backup.skykick.com';
      break;
    default:
      baseUrl = `https://sk-${environment.environment}-portal.skykick.com`;
    }

    // different routes in PP have different requirements for a slash at the end of the url
    let slash = '';
    let partnerPortalType: string;
    switch (this.subscription.subscriptionType) {
    case BackupSubscriptionTypes.Groups:
    case BackupSubscriptionTypes.Teams:
      partnerPortalType = 'office365groups';
      break;
    default:
      partnerPortalType = this.subscription.subscriptionType.toLowerCase();
      slash = '/';
      break;
    }

    const url =  `${baseUrl}/partner/cloud-backup/manager#/order/${this.subscription.backupServiceId}/browse/${partnerPortalType}/${this.subscription.id}${slash}`;
    window.open(url);
  }

  getBackupSize(): void {
    this.backupSizeLoading = true;
    this.apiService.fetchSubscriptionBackupSize(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe({
        next: size => this.subscription.backupSize = size,
        complete: () => this.backupSizeLoading = false
      });
  }

  getUserLicenseDetails(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderId: this.subscription.backupServiceId,
      o365Id: this.subscription.o365Id
    };
    const dialogRef = this.dialog.open(LicenseDetailsModalComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  getGraphExplorerUri(): string {
    switch (this.subscription.subscriptionType) {
    case BackupSubscriptionTypes.Exchange:
      return `users/${this.subscription.o365Id}`;
    case BackupSubscriptionTypes.SharePoint:
      return `sites/${this.subscription.o365Id}`;
    case BackupSubscriptionTypes.OneDrive:
      return `users/${this.subscription.o365Id}/drives`;
    case BackupSubscriptionTypes.Groups:
      return `groups/${this.subscription.o365Id}`;
    case BackupSubscriptionTypes.Teams:
      return `teams/${this.subscription.o365Id}`;
    default:
      return '';
    }
  }

  displayPurgeModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      subscription: this.subscription,
    };
    const dialogRef = this.dialog.open(PurgeModalComponent, dialogConfig);
    dialogRef.afterClosed();
  }

  displayElasticSearchModal(): void {
    this.messageService.openCustomModal(ElasticDetailsModalComponent, { subscription: this.subscription });
  }

  getEnabledIconTitle(): string {
    if(this.subscription.enabled && !this.subscription.disabledDueToAccess) {
      return 'Enabled'
    } else if (this.subscription.enabled && this.subscription.disabledDueToAccess) {
      const disabledReason = this.mapDisabledReason(this.subscription.subscriptionType, this.subscription.disabledReason);
      const subReason = this.mapDisabledSubReason(this.subscription.subscriptionType, this.subscription.disabledReason, this.subscription.disabledSubReason);

      if(disabledReason != null) {
        if(subReason) {
          return `${disabledReason} (${subReason})`;
        } else {
          return disabledReason;
        }
      } else if(subReason != null) {
        return `Lost Access (${subReason})`;
      } else {
        return 'Lost Access';
      }
    } else {
      return 'Disabled';
    }
  }

  private mapDisabledReason(subscriptionType: string, disabledReason?: number): string {
    if(disabledReason != null) {
      switch(disabledReason) {
      case 0: return "Unable To Access";
      case 1: return "Deactivated";
      case 4: return "Order Closed";
      case 10: return "Public Folder Impersonation Exception";
      case 11: return "Mailbox Deleted";
      case 99999: return "Team Chats Disabled (99999)";
      case 77777: return "Team Chats Disabled (77777)";
      default: return `Unknown (${disabledReason})`;
      }
    }
    return null;
  }

  private mapDisabledSubReason(subscriptionType: string, disabledReason?: number, subReason?: number): string {
    if(subReason) {
      if(subscriptionType == "OneDrive" || subscriptionType == "SharePoint") {
        // IF the disabled reason is 0, Unable to access, we will display the sub reason (lastPingResult).
        // For all other cases, I don't believe the lastPingResult is relevant.
        if(disabledReason == 0 || disabledReason == null) {
          switch(subReason) {
          case 772370000: return "Unknown Error";
          case 772370001: return "Success";
          case 772370002: return "InsufficientPermissions";
          case 772370003: return "Missing Resource";
          case 772370004: return "Throttled";
          case 772370005: return "Intentionally Skipped";
          }
        }
      }
      return `Unknown(${this.subscription.disabledSubReason})`;
    }
    return null;
  }

  determinePreparationStateEnumValue(): void {
    // Get the actual enumeration value from the string:
    this.prepStateEnum = <PreparationState>PreparationState[this.subscription.preparationState];
    // Verify the state is valid:
    if(this.prepStateEnum != null) {
      this.preparationState = `${this.prepStateEnum} - ${this.subscription.preparationState}`;
    } else {
      this.preparationState = `Unknown (${this.subscription.preparationState})`;
    }
  }

  getRelatedResource(): { name: string, url: string, icon: string } {
    const orderId = this.subscription.backupServiceId;
    const relatedResourceId = this.subscription.relatedResourceId;

    const urlBase = `orders/${orderId}/usersgroups`;
    let url = '';

    let name = '';
    let icon = '';

    if (this.subscription.resourceKey.subscriptionType == 'O365Groups') {
      name = 'Related Group';
      icon = "group";
      url = `${urlBase}/groups/${relatedResourceId}`
    } else {
      name = 'Related User';
      icon = "person";
      url = `${urlBase}/users/${relatedResourceId}`
    }

    return { name, url, icon };
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  displayBlobContainerSize(): void {
    this.messageService.openCustomModal(BlobSizeModalComponent, { subscription: this.subscription });
  }
}
