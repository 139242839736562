<div *ngIf="order" class="details-container">
    <div class="details-title has-menu">
        <div class="d-flex">
            {{order.name}}
            <sk-click-to-copy
                hoverText="Click to copy name"
                [copyText]="order.name">
            </sk-click-to-copy>
        </div>

        <div *skIfIframeRemove>
            <mat-icon class="bookmark material-icons-outlined" (click)="toggleBookmark()" matTooltip="Bookmark Order">
                {{isBookmarked ? 'star' : 'star_outline'}}
            </mat-icon>
        </div>

        <div>
            <mat-icon class="menu clickable" [matMenuTriggerFor]="orderOptions">
                menu
            </mat-icon>
        </div>
    </div>

    <mat-menu #orderOptions="matMenu">
      <button mat-menu-item
        (click)="openJumpToPartnerPortal()"
        [matMenuTriggerFor]="impersonate">
        Impersonate
      </button>
      <button mat-menu-item
        [matMenuTriggerFor]="discovery">
        Run Discovery
      </button>
      <button mat-menu-item
        [matMenuTriggerFor]="dataDog">
        DataDog
      </button>
      <button mat-menu-item
        (click)="openAlertsPortal()">
        Alerts Portal
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </button>
      <button mat-menu-item
        (click)="openManagementPortal()">
        Management Portal
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </button>
    </mat-menu>

    <mat-menu #impersonate="matMenu">
      <button mat-menu-item
        (click)="openJumpToPartnerPortal()">
        Impersonate
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </button>
      <button mat-menu-item *ngIf="isDeveloperLinksVisible()"
        (click)="openJumpToPartnerPortalLocal()">
        Impersonate (localhost)
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </button>
    </mat-menu>

    <mat-menu #discovery="matMenu">
        <button mat-menu-item
          (click)="enqueueJob('discovery', 'exchange')">
          Exchange
        </button>
        <button
            mat-menu-item
            (click)="enqueueJob('discovery', 'sharePoint')">
            SharePoint
        </button>
        <button
            mat-menu-item
            (click)="enqueueJob('discovery', 'oneDrive')">
            OneDrive
        </button>
        <button
            mat-menu-item
            (click)="enqueueJob('discovery', 'groups')">
            Groups
        </button>
        <button
            mat-menu-item
            (click)="enqueueJob('discovery', 'teamsChats')">
            Teams Chats
        </button>
        <button
            mat-menu-item
            (click)="enqueueJob('discovery', 'usersAndGroups')">
            Users and Groups
        </button>
    </mat-menu>

    <mat-menu #dataDog="matMenu">
      <button mat-menu-item
        (click)="openDataDogLogs()">
        Log Explorer
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </button>
      <button mat-menu-item
        class="option-menu-button"
        (click)="openDataDogRum()">
        Rum
        <mat-icon class="right-icon">open_in_new</mat-icon>
      </button>
    </mat-menu>

    <div class="details-badges">
        <mat-icon *ngIf="order.disabledDueToNonPaymentOn"
            class="error"
            inline
            title="Disabled Due To Non-Payment: {{order.disabledDueToNonPaymentOn | friendlyDate}}">
            credit_card_off
        </mat-icon>
        <mat-icon *ngIf="order.state"
            inline
            fontSet="material-icons-outlined"
            [class]="order.stateIconClass"
            [title]="'Order State: ' + order.stateIconTitle">
            {{order.stateIcon}}
        </mat-icon>
        <sk-badge *ngIf="promo && promo.source"
          [text]="promo.sourceAbbreviation"
          colorClass="blue"
          [title]="'Promo Source: ' + promo.sourceTitle">
        </sk-badge>
        <sk-badge *ngIf="!order.orderType"
          text="V1"
          colorClass="red"
          title="This is a V1 Backup Order">
        </sk-badge>
        <sk-badge *ngIf="order.orderType && (!promo.orderType || (promo.orderType == order.orderType))"
          [text]="order.orderType"
          colorClass="blue"
          [title]="'Order Type: ' + order.orderType">
        </sk-badge>
        <sk-badge *ngIf="order.orderType && promo.orderType && (order.orderType != promo.orderType)"
          [text]="promo.orderType"
          colorClass="red"
          [title]="'Order Type (' + order.orderType + ') does not match Promo Order Type (' + promo.orderType + ')'"
          icon="warning">
        </sk-badge>
        <sk-badge *ngIf="order.isIur"
          text="IUR"
          colorClass="blue"
          title="IUR (likely BYOA, but not always)">
        </sk-badge>
        <sk-badge *ngIf="order.isTest"
          text="Test"
          colorClass="red"
          title="Test">
        </sk-badge>
    </div>

    <div class="details-table">
        <div id="id">
            <span class="label">Id:</span>
            <span class="d-flex">
                {{order.id}}
                <sk-click-to-copy
                    [copyText]="order.id"
                    hoverText="Click to copy id">
                </sk-click-to-copy>
            </span>
        </div>
        <div id="domain">
          <span class="label">OnMicrosoft Domain:</span>
          <span *ngIf="order.domain" class="d-flex">{{order.domain}}
              <sk-click-to-copy
                  [copyText]="order.domain"
                  hoverText="Click to copy OnMicrosoft Domain">
              </sk-click-to-copy>
          </span>
          <span *ngIf="!order.domain">-</span>
        </div>
        <div id="crm-order">
            <span class="label">Crm Order:</span>
            <a [href]="order.salesOrderUrl" class="clickable pop-out break-word" target="_blank" title="Open CRM order in new tab">
                {{order.name}}
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <div id="crm-migrations-order" *ngIf="order.migrationSalesOrderUrl">
            <span class="label">Migrations Order:</span>
            <a [href]="order.migrationSalesOrderUrl" class="clickable pop-out migrations-order-link" target="_blank" title="Open CRM order in new tab">
                CRM
                <mat-icon>open_in_new</mat-icon>
            </a>
            <a *ngIf="atcUrl" [href]="atcUrl" class="clickable pop-out migrations-order-link" target="_blank" title="Open ATC order in new tab">
                ATC
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <div id="crm-partner">
            <span class="label">Crm Partner:</span>
            <a [href]="order.managingPartnerUrl" class="clickable pop-out break-word" target="_blank" title="Open CRM partner in new tab">
                {{order.managingPartnerName}}
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <div id="partner-orders">
            <span class="label"></span>
            <a [routerLink]="['/partners', order.managingPartnerId]"
              class="clickable pop-out break-word" target="_blank" title="Open partner orders" routerLinkActive="active">
                Partner Orders
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <div id="orderState">
            <span class="label">Order State:</span>
            <span>{{order.state}} ({{order.stateChanged | friendlyDate}})</span>
        </div>
        <div id="tenantLocale">
            <span class="label">Tenant Locale:</span>
            <span>{{order.tenantLocale}}</span>
        </div>
        <div id="created-on">
            <span class="label">Created:</span>
            <span>{{order.createdOn | friendlyDate}}</span>
        </div>
        <div id="placed-on">
            <span class="label">Placed:</span>
            <span title="The order was originally placed at this time via this source.">
                {{order.placedOn | friendlyDate}} (Source: {{order.placementSourceTitle}})
            </span>
        </div>
        <div id="datacenter-name">
            <span class="label">Storage Data Center:</span>
            <span *ngIf="order.dataCenterName">{{order.dataCenterName}}</span>
            <span *ngIf="!order.dataCenterName">BYOA</span>
        </div>
        <div id="search-cluster-name">
            <span class="label">Search Cluster:</span>
            <span *ngIf="order.searchClusterName">{{order.searchClusterName}}</span>
            <span *ngIf="!order.searchClusterName">--</span>
        </div>
    </div>

    <div class="product-details-container">
        <div *ngFor="let productType of getProductTypes()" class="product-details">
            <div class="product-title">
                <span>{{order.products[productType].productName}}</span>
                <mat-icon
                    class="product-badge md-34"
                    [class.success]="order.products[productType].enabled"
                    [class.error]="!order.products[productType].enabled"
                    title="{{ order.products[productType].enabled ? 'Enabled' : 'Disabled' }}">
                    power_settings_new
                </mat-icon>
                <sk-badge *ngIf="order.products[productType].licenseLimit"
                    [text]="order.products[productType].licenseLimit"
                    class="product-badge"
                    colorClass="blue"
                    [title]="'License Limit: ' + order.products[productType].licenseLimit">
                </sk-badge>
                <mat-icon *ngIf="order.products[productType].disabledByPromo"
                    class="product-badge blue md-34"
                    title="Disabled by promo">
                    lock
                </mat-icon>
            </div>
            <div class="details-table">
                <div>
                    <span class="label">Seat Count:</span>
                    <span class="seat-count">
                        <span title="Total Discovered" class="value-total">
                            {{order.products[productType].enabledSeats +
                            order.products[productType].disabledDueToAccessSeats +
                            order.products[productType].disabledSeats}}
                        </span>
                        <span class="open-bracket">(</span>
                        <span title="Enabled">
                            {{ order.products[productType].enabledSeats }}
                        </span>
                        <span class="separator">/</span>
                        <span title="Disabled Due To Access">
                            {{ order.products[productType].disabledDueToAccessSeats }}
                        </span>
                        <span class="separator">/</span>
                        <span title="Disabled">
                            {{ order.products[productType].disabledSeats }}
                        </span>
                        <span class="close-bracket">)</span>
                    </span>
                </div>
                <div>
                    <span class="label">Placed:</span>
                    <span>{{order.products[productType].productOrderPlaced | friendlyDate}}</span>
                </div>
                <div *ngIf="order.products[productType].productOrderDisabled">
                    <span class="label">Disabled:</span>
                    <span>{{order.products[productType].productOrderDisabled | friendlyDate}}</span>
                </div>
                <div>
                    <span class="label">Discovery:</span>
                    <span>{{order.products[productType].enabled ? order.products[productType].newDetectedAction : '--'}}</span>
                </div>
                <div>
                    <span class="label">Retention:</span>
                    <span>{{order.products[productType].snapshotRetentionDays}} days ({{getBackupRetentionPolicy(order.products[productType].snapshotRetentionDays)}})</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!order" class="loading-spinner">
    <mat-spinner></mat-spinner>
</div>
