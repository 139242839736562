import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TabsDispatchService } from '@sk-services';
import { ApiService } from '../api-service/api.service';

@Component({
  selector: 'sk-graph-explorer',
  templateUrl: './graph-explorer.component.html',
  styleUrls: ['./graph-explorer.component.scss']
})
export class GraphExplorerComponent implements OnInit {
  @HostBinding('class') class ='d-flex flex-grow-1';

  orderId: string;
  activeTab: string;

  constructor(
    private route: ActivatedRoute,
    private tabsDispatchService: TabsDispatchService,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('id');

    const lastUrlSegment = this.route.snapshot.url[3] &&
      this.route.snapshot.url[3].path;
    if (lastUrlSegment === 'explorer' ||
        lastUrlSegment === 'information' ||
        lastUrlSegment === 'ms-graph-explorer') {
      this.activeTab = lastUrlSegment;
      this.tabsDispatchService.setSubTab('graph-explorer', lastUrlSegment);
      if (lastUrlSegment === 'ms-graph-explorer') {
        this.setAccessToken();
      }
    } else {
      const subTab = this.tabsDispatchService.getSubTab('graph-explorer') || 'explorer';
      this.router.navigateByUrl(this.router.url + `/${subTab}`);
    }
  }

  setAccessToken(): void {
    this.apiService.getGraphToken(this.orderId)
      .subscribe(token=> {
        localStorage.setItem('graph-api-token', token);
        window.location.href = `/ms-graph-explorer/${this.orderId}`;
      });
  }
}
