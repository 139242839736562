import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/api-service/api.service';
import { SkippedItemsReportRequest } from '@sk-models';
import { SkyKickComponent } from '../core/skykick-component.base';
import { saveAs } from 'file-saver';

@Component({
  selector: 'sk-restore-skipped-items-export',
  templateUrl: './restore-skipped-items-export.component.html',
  styleUrls: ['./restore-skipped-items-export.component.scss']
})
export class RestoreSkippedItemsExportComponent extends SkyKickComponent {
  isLoading: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SkippedItemsReportRequest,
    public dialogRef: MatDialogRef<RestoreSkippedItemsExportComponent>,
    private apiService: ApiService) {
    super();
  }

  onExportSkippedItems(): void {
    this.errorMessage = null;
    this.isLoading = true;
    this.subs.sink = this.apiService.exportSkippedRestoreItems(this.data.restoreId, this.data.resourceKey, this.data.sourceResourceKey)
      .subscribe(items => this.downloadFile(items))
      .add(() => this.isLoading = false);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  downloadFile(fileData: any): void {
    if (fileData) {
      saveAs(fileData, `Skipped Items ${this.data.restoreId}.xlsx`);
    }
  }

}
