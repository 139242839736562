import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';

import { ApiService } from '../../api-service/api.service';
import { ClickToCopyService } from '../../services/click-to-copy-service/click-to-copy.service';
import { GraphQueryPage, GraphQueryResponse } from '@sk-models';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: 'sk-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss']
})

export class ExplorerComponent implements OnInit {

  @Input() orderId: string;
  activeTab: string;

  isLoading: boolean;
  defaultQueryView: boolean;

  graphForm: FormGroup;
  queryResultsCurrentPage: GraphQueryPage;
  queryResultPages: GraphQueryPage[] = [];
  endpoints: string[][];
  betaEndpoint: boolean;
  selectedEndpoint: string;

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private apiService: ApiService,
    private copyService: ClickToCopyService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.graphForm = new FormGroup({
      uri: new FormControl()
    });

    this.betaEndpoint = false;
    this.defaultQueryView = true;
    this.queryResultPages = [];
    const loadingSubscription = this.loadDefaultGraphQueries();

    if (this.route.snapshot?.queryParamMap?.get('uri')) {
      const version = this.route.snapshot?.queryParamMap?.get('version');
      // if version param is not provided, fallback to v1.0
      this.betaEndpoint = version && version === 'beta';

      // we need this because both subscriptions (loadDefaultGraphQueries and onSubmit)
      // set this.isLoading to false at the end, so we first have to wait for the
      // loadDefaultGraphQueries to complete and the start loading data
      loadingSubscription.add(() => {
        this.defaultQueryView = false;
        this.graphForm.get('uri').setValue(this.route.snapshot?.queryParamMap?.get('uri'));
        this.onSubmit(false);
      });
    }
  }

  loadDefaultGraphQueries(): Subscription {
    this.isLoading = true;
    return this.apiService.getGraphEndpointOptions()
      .subscribe({
        next: endpoints => this.endpoints = endpoints,
        complete: () => this.isLoading = false
      });
  }

  toggleQueryView(): void {
    this.defaultQueryView = !this.defaultQueryView;
  }

  onSubmit(defaultQuery: boolean): void {
    this.queryResultsCurrentPage = null;
    this.queryResultPages = [];

    if (defaultQuery === true) {
      this.queryGraphPredefined(this.selectedEndpoint);
    } else {
      this.queryGraphCustom(this.graphForm.get('uri').value, this.betaEndpoint);
    }
  }

  canPageBackwards(): boolean {
    return !this.isLoading &&
      this.queryResultsCurrentPage.pageNumber > 1;
  }

  pageBackwards(): void {
    const previousPageIndex = this.queryResultsCurrentPage.pageNumber - 2;
    this.queryResultsCurrentPage = this.queryResultPages[previousPageIndex];
  }

  canPageForwards(): boolean {
    return !this.isLoading &&
      Boolean(this.queryResultsCurrentPage.pageNumber < this.queryResultPages.length ||
      this.queryResultsCurrentPage.nextPageUri);
  }

  pageForwards(): void {
    // page is already loaded, just move forward
    if (this.queryResultsCurrentPage.pageNumber < this.queryResultPages.length) {
      const nextPageIndex = this.queryResultsCurrentPage.pageNumber;
      this.queryResultsCurrentPage = this.queryResultPages[nextPageIndex];
    // load next page
    } else if (this.queryResultsCurrentPage.nextPageUri) {
      this.queryGraphCustom(this.queryResultsCurrentPage.nextPageUri, this.betaEndpoint);
    }
  }

  jumpToPage(pageNumber: number): void {
    if (pageNumber !== this.queryResultsCurrentPage.pageNumber) {
      this.queryResultsCurrentPage = this.queryResultPages[pageNumber - 1];
    }
  }

  queryGraphCustom(uri: string, betaEndpoint: boolean): void {
    this.isLoading = true;

    // save state in the query string
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          version: this.betaEndpoint ? 'beta' : 'v1.0',
          uri: uri
        },
        replaceUrl: true
      });

    this.apiService.queryGraphCustom(this.orderId, uri, betaEndpoint)
      .subscribe({
        next: rawResult => this.parseGraphQueryResults(rawResult),
      })
      .add(() => this.isLoading = false);
  }

  queryGraphPredefined(predefinedQueryName: string): void {
    this.isLoading = true;
    this.apiService.queryGraphPredefined(this.orderId, predefinedQueryName)
      .subscribe({
        next: response => this.parseGraphQueryResults(response),
      })
      .add(() => this.isLoading = false);
  }

  parseGraphQueryResults(response: GraphQueryResponse): void {
    const json = JSON.parse(response.content);

    const newPage: GraphQueryPage = {
      pageNumber: this.queryResultPages.length + 1,
      content: json,
      nextPageUri: response.nextPageUri
    };
    this.queryResultPages.push(newPage);
    this.queryResultsCurrentPage = newPage;
  }

  copyJson(): void {
    if (this.queryResultsCurrentPage) {
      this.copyService.copyToClipboardJSON(this.queryResultsCurrentPage.content);
    }
  }
}
