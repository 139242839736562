<h2 mat-dialog-title>BSP Version Info</h2>
<mat-dialog-content class="mat-typography">  
  <div>
    <p>
      <b>Version:</b> {{ngBuildInfo.version}}
    </p>
    <p>
      <b>Build Date:</b> {{ngBuildInfo.buildDate}}
    </p>
  </div>
</mat-dialog-content>
<div class="brightness">
  <mat-icon *ngIf="!darkTheme" class="clickable" (click)="switchTheme()">brightness_high</mat-icon>
  <mat-icon *ngIf="darkTheme" class="clickable" (click)="switchTheme()">brightness_low</mat-icon>
</div>
