import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SkyKickComponent } from 'app/shared/core/skykick-component.base';
import { SearchFilter, UsersGroupsSearchFilter } from 'app/users-groups/models/users-groups-search.model';
import { CollaborationService } from 'app/users-groups/services/collaboration.service';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ODataResponse } from '@sk-models';
import { TeamsGroups } from 'app/users-groups/models/teamsgroups.model';

@Component({
  selector: 'sk-teamsgroups-search-grid',
  templateUrl: './teamsgroups-search-grid.component.html',
  styleUrls: ['./teamsgroups-search-grid.component.scss']
})
export class TeamsGroupsSearchGridComponent extends SkyKickComponent implements OnInit {

  @Input() backupServiceId: string;
  @Input() isTeam: boolean;
  @Input() filterSearchTerm: string;

  public isLoading = true;
  public hasData = false;

  // Pagination
  pageSizeOptions = [12, 50, 100];
  totalResults: number;

  subject = new Subject();
  filter: UsersGroupsSearchFilter;

  dataSource: MatTableDataSource<TeamsGroups>;
  displayedColumns = ['id', 'displayName', 'type', 'protected', 'created'];

  constructor(private collaborationService: CollaborationService) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.subs.sink = this.subject.pipe(
      switchMap((data: UsersGroupsSearchFilter) => {
        this.isLoading = true;
        return this.executeSearch(data);
      })
    ).subscribe((tg: ODataResponse<TeamsGroups>) => {
      this.hasData = true;
      this.totalResults = tg['@odata.count'];
      this.dataSource = new MatTableDataSource(tg.value);
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    });
    this.filter = {
      page: 0,
      size: this.pageSizeOptions[0],
      sort: 'name',
      sortDirection: 'asc',
      term: this.filterSearchTerm };
  }

  public refresh(filter: SearchFilter): void {
    this.filter = {
      ...this.filter,
      term: filter.term
    };
    this.subject.next(this.filter);
  }

  private executeSearch(filter: UsersGroupsSearchFilter): Observable<ODataResponse<TeamsGroups>> {
    return this.collaborationService
      .searchTeamsGroups(this.backupServiceId, this.isTeam, filter);
  }

  onPageChanged(event: PageEvent): void {
    this.filter.size = event.pageSize;
    this.filter.page = event.pageIndex;
    this.subject.next(this.filter);
  }

  onFilterChanged(term: string): void {
    this.filter = {
      ...this.filter,
      term: term
    };
  }

  onSortChanged(sort: Sort): void {
    this.filter.sort = sort.active;
    this.filter.sortDirection = (sort.direction);
    this.subject.next(this.filter);
  }

  getSubscriptionDetailLink(tg: TeamsGroups): string {
    return `/orders/${tg.backupServiceId}/usersgroups/teamsgroups/${tg.id}/`

    const urlBase = `/orders/${tg.backupServiceId}/subscriptions/${tg.id}/`;
    return tg.isTeam
      ? `${urlBase}/Team/subscription-details`
      : `${urlBase}/TeamSite/subscription-details`;
  }
}
