<div class="page-container">
  <div class="page-content" *ngIf="groupDetails != null && isLoading == false">
    <sk-page-header [title]="groupDetails.name" icon="group" [breadcrumb]="[{
        'title': 'Resources',
        'link': '../../'
      }, {
        'title': 'Group'
      }]">
      <div slot="header-content" class="header-content">
        <sk-users-groups-status-indicator
          [enabled]="groupDetails.enabled"
          [overridden]="groupDetails.overridden"
          [disabledDueToAccess]="groupDetails.disabledDueToAccess"
          scope="Group">
        </sk-users-groups-status-indicator>
      </div>
      <ng-container slot="header-actions">
        <button mat-icon-button [matMenuTriggerFor]="userOptions">
          <mat-icon class="clickable">menu</mat-icon>
        </button>
        <mat-menu #userOptions="matMenu">
          <a mat-menu-item [routerLink]="['audit']">Audit</a>
        </mat-menu>
      </ng-container>
    </sk-page-header>
    <div class="page-content" *ngIf="!isLoading">
      <div class="widget-container">
        <sk-widget title="Details" [collapsible]="true" [expanded]="true">
          <sk-widget-data>
            <sk-widget-data-row label="Name" [value]="groupDetails.name" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="ID" [value]="groupDetails.id" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="O365 ID" [value]="groupDetails.nativeId" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="Created On" [value]="groupDetails.created" dataType="date"></sk-widget-data-row>
            <sk-widget-data-row label="EmailAddress" [value]="groupDetails.emailAddress" clickToCopy="true"></sk-widget-data-row>
            <sk-widget-data-row label="Type" [value]="getGroupTypeName(groupDetails.groupType)"></sk-widget-data-row>
          </sk-widget-data>
        </sk-widget>
      </div>
      <div class="widget-container">
        <sk-widget title="User Membership" [collapsible]="true" [noBorder]="true" (expandEvent)="refreshUserMembership()">
          <sk-users-groups-search-filter (filterChange)="onUserSearchFilterChanged($event)"></sk-users-groups-search-filter>
          <sk-users-search-grid #userMembershipSearch
            [backupServiceId]="backupServiceId"
            [groupId]="groupId">
          </sk-users-search-grid>
        </sk-widget>
      </div>
      <div class="widget-container">
        <sk-widget title="Group Membership" [collapsible]="true" [noBorder]="true" (expandEvent)="refreshGroupMembership()">
          <sk-users-groups-search-filter (filterChange)="onGroupSearchFilterChanged($event)"></sk-users-groups-search-filter>
          <sk-groups-search-grid #groupMembershipSearch
            [backupServiceId]="backupServiceId"
            [groupId]="groupId">
          </sk-groups-search-grid>
        </sk-widget>
      </div>
      <div class="widget-container">
        <sk-users-groups-related-resources [resources]="resources" [backupServiceId]="backupServiceId"
          alwaysShow="false"></sk-users-groups-related-resources>
      </div>
    </div>
    <div class="page-content" *ngIf="groupDetails == null && isLoading == false">
      No data available
    </div>
  </div>
  <div class="page-content flex-container" *ngIf="isLoading == true">
    <div class="loading-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
