<div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form *ngIf="!isRefreshing" [formGroup]="filterForm" class="filter-form-content">
        <mat-grid-list [cols]="4" rowHeight="75px" class="filter-selectors">
          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <mat-form-field>
              <mat-icon
                  class="info-icon"
                  matTooltip="State provides a sub-status to explain what the order looks like. (e.g. cancelled is inactive, but available to be made active again.)">
                  info
              </mat-icon>
              <mat-select placeholder="Order State" formControlName="orderState" (selectionChange)="stateSelected($event)">
                <mat-option [value]="">All</mat-option>
                <mat-option [value]="'Active'">Active</mat-option>
                <mat-option [value]="'Archived'">Archived</mat-option>
                <mat-option [value]="'Cancelled'">Cancelled</mat-option>
                <mat-option [value]="'Deactivated'">Deactivated</mat-option>
                <mat-option [value]="'DisabledDueToNonpayment'">DisabledDueToNonpayment</mat-option>
                <mat-option [value]="'Inactive'">Inactive</mat-option>
                <mat-option [value]="'OrderPending'">OrderPending</mat-option>
                <mat-option [value]="'PurgeRequested'">PurgeRequested</mat-option>
                <mat-option [value]="'Purged'">Purged</mat-option>
                <mat-option [value]="'Purging'">Purging</mat-option>
                <mat-option [value]="'StateNotDefined'">StateNotDefined</mat-option>
                <mat-option [value]="'Suspended'">Suspended</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <mat-form-field>
              <mat-label>Created On or After</mat-label>
              <input matInput [matDatepicker]="createdDateFromPicker"
                formControlName="createdDateFrom" (dateChange)="createdDateFromChange($event)">
              <mat-datepicker-toggle matSuffix [for]="createdDateFromPicker"></mat-datepicker-toggle>
              <mat-datepicker #createdDateFromPicker></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [rowspan]="1" [colspan]="1">
            <mat-form-field>
              <mat-label>Created On or Before</mat-label>
              <input matInput [matDatepicker]="createdDateToPicker"
                formControlName="createdDateTo" (dateChange)="createdDateToChange($event)">
              <mat-datepicker-toggle matSuffix [for]="createdDateToPicker"></mat-datepicker-toggle>
              <mat-datepicker #createdDateToPicker></mat-datepicker>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="grid-container">
    <div class="grid-container">
      <sk-click-to-refresh
        class="refresh"
        [shouldDisplay]="dataSource"
        [spin]="isRefreshing"
        (clickEvent)="refreshOrders()">
      </sk-click-to-refresh>

      <div class="table-container">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              <span>Id</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <sk-click-to-copy hoverText="{{row.id}}" [copyText]="row.id">
              </sk-click-to-copy>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-icon
                [class]="row.stateIconClass"
                [title]="'Order State: ' + row.stateIconTitle">
                {{row.stateIcon}}
              </mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a href="{{baseUrl}}/orders/{{row.id}}" title="Open the order in a new tab"
                target="_blank">
                {{row.name || '--'}}
                <mat-icon>open_in_new</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="orderType">
            <mat-header-cell *matHeaderCellDef>
              <span>Order Type</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.orderType}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Created</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>{{row.createdOn | friendlyDate:"MM/DD/YY hh:mm A"}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="placedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Placed On</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>{{row.placedOn | friendlyDate:"MM/DD/YY hh:mm A"}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeEnabledSeatsTotal">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Total</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['exchange']">
                {{row.products['exchange'].enabledSeats +
                  row.products['exchange'].disabledSeats +
                  row.products['exchange'].disabledDueToAccessSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeEnabledSeatsEnabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>
                <mat-icon title="Enabled" class="success">
                  power_settings_new
                </mat-icon>
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['exchange']">
                {{row.products['exchange'].enabledSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeEnabledSeatsDisabledDueToAccess">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Disabled Due To Access" class="warning">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['exchange']">
                {{row.products['exchange'].disabledDueToAccessSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exchangeEnabledSeatsDisabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Disabled" class="error">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['exchange']">
                {{row.products['exchange'].disabledSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sharePointEnabledSeatsTotal">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Total</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['sharePoint']">
                {{row.products['sharePoint'].enabledSeats +
                  row.products['sharePoint'].disabledSeats +
                  row.products['sharePoint'].disabledDueToAccessSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sharePointEnabledSeatsEnabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Enabled" class="success">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['sharePoint']">
                {{row.products['sharePoint'].enabledSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sharePointEnabledSeatsDisabledDueToAccess">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon icon="power-off" title="Disabled Due To Access" class="warning">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['sharePoint']">
                {{row.products['sharePoint'].disabledDueToAccessSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sharePointEnabledSeatsDisabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Disabled" class="error">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['sharePoint']">
                {{row.products['sharePoint'].disabledSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupsEnabledSeatsTotal">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Total</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['groups']">
                {{row.products['groups'].enabledSeats +
                  row.products['groups'].disabledSeats +
                  row.products['groups'].disabledDueToAccessSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupsEnabledSeatsEnabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Enabled" class="success">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['groups']">
                {{row.products['groups'].enabledSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupsEnabledSeatsDisabledDueToAccess">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Disabled Due To Access" class="warning">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['groups']">
                {{row.products['groups'].disabledDueToAccessSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="groupsEnabledSeatsDisabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-icon title="Disabled" class="error">
                power_settings_new
              </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div *ngIf="row.products['groups']">
                {{row.products['groups'].disabledSeats}}
              </div>
            </mat-cell>
          </ng-container>

          <!-- Header row first group -->
          <ng-container matColumnDef="header-row-first-group">
            <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="6">
              Order Info
            </th>
          </ng-container>

          <!-- Header row second group -->
          <ng-container matColumnDef="header-row-second-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> Exchange Seats </th>
          </ng-container>

          <ng-container matColumnDef="header-row-third-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> SharePoint Seats </th>
          </ng-container>

          <ng-container matColumnDef="header-row-fourth-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> Groups Seats </th>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group', 'header-row-fourth-group']">
          </mat-header-row>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="isRefreshing" class="loading-spinner">
          <mat-spinner title="looking up events for this request id....."></mat-spinner>
        </div>
      </div>

      <mat-paginator #paginator
        [hidden]="isRefreshing"
        [length]="totalCount"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"
        showFirstLastButtons>
      </mat-paginator>

    </div>
  </div>
</div>
