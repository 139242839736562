<form [formGroup]="filterForm" class="flex-row-container">
  <div class="flex-row">
    <div class="flex-item search-form-field">
      <mat-form-field>
        <input matInput placeholder="Search..." formControlName="searchInput" #input>
      </mat-form-field>
    </div>

    <div class="flex-item filter-drop">
      <mat-form-field>
        <mat-select placeholder="Subscription Type" formControlName="subscriptionType">
          <mat-option [value]="'Any'">Any</mat-option>
          <mat-option *ngFor="let subscriptionType of subscriptionTypes" [value]="subscriptionType">
            {{ subscriptionType ? subscriptionType : '--' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-item filter-drop">
      <mat-form-field>
        <mat-select placeholder="Status" formControlName="resourceStatus">
          <mat-option [value]="'Any'">Any</mat-option>
          <mat-option [value]="true">Enabled</mat-option>
          <mat-option [value]="false">Disabled</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex-item">
      <button mat-raised-button (click)="resetFilterControl()" class="reset-button">
        <mat-icon>clear</mat-icon>
        <span>Reset Filters</span>
      </button>
    </div>
  </div>
</form>
