<form [formGroup]="filterForm" class="filter-form-content">
  <mat-grid-list [cols]="5" rowHeight="75px" class="filter-selectors">
    <mat-grid-tile [rowspan]="1" [colspan]="3">
      <mat-form-field class="search-form-field">
        <input matInput placeholder="Search..." formControlName="searchInput">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [rowspan]="1" [colspan]="1" *ngIf="resourceTypes">
      <mat-form-field>
        <mat-select placeholder="Type" formControlName="resourceType">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let resourceType of resourceTypes" [value]="resourceType">
            {{resourceType ? resourceType : '--'}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [rowspan]="1" [colspan]="1">
      <mat-form-field>
        <mat-select placeholder="Status" formControlName="jobStatus">
          <mat-option [value]="'All'">All</mat-option>
          <mat-option *ngFor="let jobStatus of jobStatuses" [value]="jobStatus">
            {{jobStatus ? jobStatus : '--'}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile [rowspan]="1" [colspan]="1">
      <button mat-raised-button (click)="resetCurrentFilter()">
        <mat-icon>clear</mat-icon>
        <span>Reset Filters</span>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</form>
