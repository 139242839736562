<mat-list-item class="clickable nav-item"
  (mouseenter)="onMouseEnter()"
  (click)="onClick($event)"
  [routerLink]="routerLink"
  [matTooltip]="label"
  matTooltipPosition="after"
  [matTooltipDisabled]="hideTooltip || isExpanded">
  <ng-container *ngIf="canNavigate === true; else plainLabel">
    <a [routerLink]="routerLink"
      rel="noopener noreferrer"
      (click)="onLinkClick($event, routerLink)">
      <ng-container *ngIf="iconName; then iconTemplate; else imgTemplate"></ng-container>
      <span mat-line *ngIf="isExpanded">{{ label }}</span>
    </a>
  </ng-container>
  <ng-template #plainLabel>
    <div>
      <ng-container *ngIf="iconName; then iconTemplate; else imgTemplate"></ng-container>
      <span mat-line *ngIf="isExpanded">{{ label }}</span>
    </div>
  </ng-template>

  <ng-template #iconTemplate>
    <mat-icon mat-list-icon *ngIf="iconName">{{ iconName }}</mat-icon>
  </ng-template>
  <ng-template #imgTemplate>
    <img mat-list-avatar *ngIf="imgSrc" [src]="imgSrc" />
  </ng-template>
</mat-list-item>
